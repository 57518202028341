import React, { useState, useEffect, useRef } from 'react';
import img1 from "./../assets/imageOne.jpeg";
import img2 from "./../assets/imageTwo.jpeg";
import img3 from "./../assets/imageThree.jpeg";
import img4 from "./../assets/imageFour.jpeg";
import img5 from "./../assets/imageFive.jpeg";
import img6 from "./../assets/imageSix.jpeg";
import img7 from "./../assets/imageSeven.jpeg";

const ImageCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imagesToShow, setImagesToShow] = useState(3);
  const autoScrollInterval = 5000; // 5 seconds
  const autoScrollRef = useRef();

  // Replace these with your actual image imports
  const images = [
    img1, 
    img2,
    img3, 
    img4, 
    img5,
    img6,
    img7,
  ];

  const totalImages = images.length;

  // Function to update imagesToShow based on window width
  const updateImagesToShow = () => {
    if (window.innerWidth < 768) {
      setImagesToShow(1); // Mobile devices
    } else {
      setImagesToShow(3); // Tablets and desktops
    }
  };

  // Initialize imagesToShow on mount and add resize listener
  useEffect(() => {
    updateImagesToShow();
    window.addEventListener('resize', updateImagesToShow);
    return () => window.removeEventListener('resize', updateImagesToShow);
  }, []);

  const next = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + imagesToShow >= totalImages ? 0 : prevIndex + imagesToShow
    );
  };

  const prev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - imagesToShow < 0
        ? Math.max(totalImages - imagesToShow, 0)
        : prevIndex - imagesToShow
    );
  };

  // Get the subset of images to display
  const getDisplayedImages = () => {
    const displayed = images.slice(currentIndex, currentIndex + imagesToShow);
    if (displayed.length < imagesToShow) {
      return displayed.concat(images.slice(0, imagesToShow - displayed.length));
    }
    return displayed;
  };

  const displayedImages = getDisplayedImages();

  // Auto-scroll functionality
  useEffect(() => {
    autoScrollRef.current = next;
  });

  useEffect(() => {
    const play = () => {
      autoScrollRef.current();
    };
    const interval = setInterval(play, autoScrollInterval);
    return () => clearInterval(interval);
  }, []);

  // Pause auto-scroll on hover
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    let interval = null;
    if (!isHovered) {
      interval = setInterval(() => {
        next();
      }, autoScrollInterval);
    } else if (isHovered && interval) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isHovered]);

  // Styling
  const styles = {
    carouselContainer: {
      width: '100%',
      maxWidth: '1200px',
      margin: '50px auto',
      textAlign: 'center',
      fontFamily: 'Arial, sans-serif',
      color: '#333',
      padding: '0 20px',
    },
    title: {
      fontSize: '2em',
      marginBottom: '20px',
      color: '#00529B', // Professional blue color
    },
    carouselWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      overflow: 'hidden',
      padding: '20px',
      borderRadius: '10px',
    },
    button: {
      padding: '10px 20px',
      margin: '0 10px',
      cursor: 'pointer',
      backgroundColor: '#00529B',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      fontSize: '1em',
      transition: 'background-color 0.3s',
    },
    imagesContainer: {
      display: 'flex',
      transition: 'transform 0.5s ease-in-out',
      width: '100%',
    },
    imageWrapper: {
      flex: `0 0 ${100 / imagesToShow - 2}%`, // Adjust width based on imagesToShow
      margin: '0 10px',
      borderRadius: '8px',
      overflow: 'hidden',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    image: {
      width: '100%',
      height: 'auto',
      objectFit: 'cover',
    },
    indicators: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '15px',
    },
    indicator: {
      height: '12px',
      width: '12px',
      margin: '0 5px',
      backgroundColor: '#bbb',
      borderRadius: '50%',
      display: 'inline-block',
      transition: 'background-color 0.3s',
      cursor: 'pointer',
    },
    activeIndicator: {
      backgroundColor: '#00529B',
    },
    slideInfo: {
      marginTop: '10px',
      fontSize: '0.9em',
      color: '#666',
    },
  };

  return (
    <div
      style={styles.carouselContainer}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <h2 style={styles.title}>Patient Satisfaction</h2>
      <div style={styles.carouselWrapper}>
        {/* <button
          onClick={prev}
          style={styles.button}
          onMouseOver={(e) => (e.target.style.backgroundColor = '#003f7d')}
          onMouseOut={(e) => (e.target.style.backgroundColor = '#00529B')}
        >
          &#10094; 
        </button> */}
        <div style={styles.imagesContainer}>
          {displayedImages.map((img, index) => (
            <div key={index} style={styles.imageWrapper}>
              <img
                src={img}
                alt={`carousel-${index + 1}`}
                style={styles.image}
              />
            </div>
          ))}
        </div>
        {/* Right Arrow */}
        {/* <button
          onClick={next}
          style={styles.button}
          onMouseOver={(e) => (e.target.style.backgroundColor = '#003f7d')}
          onMouseOut={(e) => (e.target.style.backgroundColor = '#00529B')}
        >
          &#10095; 
        </button> */}
      </div>
      {/* Indicators */}
      <div style={styles.indicators}>
        {Array.from({ length: Math.ceil(totalImages / imagesToShow) }).map(
          (_, idx) => (
            <span
              key={idx}
              style={{
                ...styles.indicator,
                ...(Math.floor(currentIndex / imagesToShow) === idx
                  ? styles.activeIndicator
                  : {}),
              }}
              onClick={() => setCurrentIndex(idx * imagesToShow)}
            ></span>
          )
        )}
      </div>
      {/* Slide Information */}
      {/* <div style={styles.slideInfo}>
         {Math.floor(currentIndex / imagesToShow) + 1} of{' '}
        {Math.ceil(totalImages / imagesToShow)}
      </div> */}
    </div>
  );
};

export default ImageCarousel;
