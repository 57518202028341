import React, { useEffect, useRef } from 'react';
import imageOne from '../assets/imageOne.jpeg';
import imageTwo from '../assets/imageTwo.jpeg';
import imageThree from '../assets/imageThree.jpeg';
import imageFour from '../assets/imageFour.jpeg';
import imageFive from '../assets/imageFive.jpeg';
import imageSix from '../assets/imageSix.jpeg';
import './AlbumPage.css';

const photos = [
  { id: 1, src: imageOne, alt: "Photo 1" },
  { id: 2, src: imageTwo, alt: "Photo 2" },
  { id: 3, src: imageThree, alt: "Photo 3" },
  { id: 4, src: imageFour, alt: "Photo 4" },
  { id: 5, src: imageFive, alt: "Photo 5" },
  { id: 6, src: imageSix, alt: "Photo 6" },
];

const AlbumPage = () => {
  const carouselRef = useRef(null);

  // Move the carousel continuously from left to right
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (carouselRef.current) {
        const currentPosition = carouselRef.current.scrollLeft;
        const scrollWidth = carouselRef.current.scrollWidth;
        const clientWidth = carouselRef.current.clientWidth;

        if (currentPosition >= scrollWidth - clientWidth) {
          carouselRef.current.scrollLeft = 0;
        } else {
          carouselRef.current.scrollLeft += 1; // Scroll by 1px
        }
      }
    }, 20); // Adjust this to change the speed of the scroll

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  return (
    <div className="album-container">
      <div className="carousel" ref={carouselRef}>
        {photos.map((photo) => (
          <div className="carousel-item" key={photo.id}>
            <img src={photo.src} alt={photo.alt} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AlbumPage;
