// RestorativeDentistry.js
import React from 'react'; 
import { Container, Row, Col, Card } from 'react-bootstrap'; 
import { Link } from 'react-router-dom';
import './PreventiveCare.css'; // Import custom CSS

const RestorativeDentistry = () => {
  const restorativeServices = [
    {
      title: 'Dental Fillings',
      description:
        'Repair cavities and restore the function and integrity of your teeth with durable fillings.',
      icon: 'flaticon-filling', // Replace with relevant icon class or image
    },
    {
      title: 'Dental Crowns',
      description:
        'Protect and strengthen damaged teeth with custom-made crowns that look and feel natural.',
      icon: 'flaticon-crown', // Replace with relevant icon class or image
    },
    {
      title: 'Dental Bridges',
      description:
        'Replace one or more missing teeth with fixed dental bridges for improved function and appearance.',
      icon: 'flaticon-bridge', // Replace with relevant icon class or image
    },
    {
      title: 'Dental Implants',
      description:
        'Permanent tooth replacement solutions that function like natural teeth.',
      icon: 'flaticon-implant', // Replace with relevant icon class or image
    },
    {
      title: 'Dentures',
      description:
        'Removable prosthetics to replace multiple missing teeth and restore your smile.',
      icon: 'flaticon-denture', // Replace with relevant icon class or image
    },
    {
      title: 'Root Canal Therapy',
      description:
        'Treat infected tooth pulp to save your natural tooth and alleviate pain.',
      icon: 'flaticon-root-canal', // Replace with relevant icon class or image
    },
  ];

  return (<>
     <section className="banner_area">
        <div className="banner_inner d-flex align-items-center">
          <div className="container">
            <div
              className="banner_content d-md-flex justify-content-between align-items-center"
            >
              <div className="mb-3 mb-md-0">
                <h2>Restorative Dentistry</h2>
                <p>                At Gondia Dental Clinic, our restorative dentistry services are designed to repair and restore your teeth to their optimal health, function, and appearance.
                </p>
              </div>
              {/* <div className="page_link">
                <Link to="/">Home</Link>
                <Link to="/preventive-care">Preventive Dental Care</Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!--================End Home Banner Area =================--> */}
  
    <section className="restorative-dentistry-area area-padding-top">
      <Container>
    

        {/* Services Section */}
        <Row className="mt-5">
          {restorativeServices.map((service, index) => (
            <Col key={index} md={6} lg={4} className="mb-4">
              <Card className="restorative-service-card h-100 text-center p-4">
                <div className="service-icon mb-3">
                  <i className={service.icon}></i>
                </div>
                <Card.Body>
                  <Card.Title>{service.title}</Card.Title>
                  <Card.Text>{service.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Benefits Section */}
        <Row className="mt-5">
          <Col lg={6}>
            <div className="benefits-image">
              <img
                src="/images/restorative-dentistry.jpg" // Replace with your image path
                alt="Restorative Dentistry"
                className="img-fluid rounded"
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="benefits-content">
              <h3>Benefits of Restorative Dentistry</h3>
              <ul className="benefits-list">
                <li>Restored function for better chewing and speaking.</li>
                <li>Improved aesthetics for a confident smile.</li>
                <li>Prevention of further dental issues.</li>
                <li>Enhanced oral health and overall well-being.</li>
                <li>Customized treatments tailored to your needs.</li>
              </ul>
              <Link to="/contact" className="learn-more btn btn-primary mt-3">
                Schedule an Appointment
              </Link>
            </div>
          </Col>
        </Row>

        {/* Optional: Testimonials Related to Restorative Dentistry */}
        <Row className="mt-5">
          <Col lg={12}>
            <div className="related-testimonials">
              <h3 className="text-center mb-4">Patient Testimonials</h3>
              <Row>
                {/* Example Testimonial */}
                <Col md={6} lg={4} className="mb-4">
                  <Card className="testimonial-card h-100 text-center p-3">
                    <Card.Body>
                      <img
                        src="/images/testimonials/john.jpg" // Replace with actual image path
                        alt="John Doe"
                        className="testimonial-photo rounded-circle mb-3"
                      />
                      <Card.Text className="testimonial-feedback">
                        "The dental implants I received at Gondia Dental Clinic have changed my life. I can eat and smile confidently again!"
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="bg-transparent border-top-0">
                      <Card.Title className="testimonial-name mb-0">- John Doe</Card.Title>
                    </Card.Footer>
                  </Card>
                </Col>
                {/* Add more testimonials as needed */}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
};

export default RestorativeDentistry;
