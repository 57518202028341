import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            {/* <!-- Start Footer Area --> */}
            <footer className="footer-area area-padding-top">
                <div className="container">
                    <div className="row">
                        {/* Our Services */}
                        <div className="col-lg-2 col-sm-6 single-footer-widget">
                            <h4>Our Services</h4>
                            <ul>
                                <li><Link to="/preventive-care">Preventive Care</Link></li>
                                <li><Link to="/restorative-dentistry">Restorative Dentistry</Link></li>
                                <li><Link to="/cosmetic-dentistry">Cosmetic Dentistry</Link></li>
                                <li><Link to="/orthodontics">Orthodontics</Link></li>
                                <li><Link to="/emergency-care">Emergency Care</Link></li>
                            </ul>
                        </div>
                        {/* Quick Links */}
                        <div className="col-lg-2 col-sm-6 single-footer-widget">
                            <h4>Quick Links</h4>
                            <ul>
                                <li><Link to="/appointments">Appointments</Link></li>
                                <li><Link to="/testimonials">Testimonials</Link></li>
                                <li><Link to="/faq">FAQs</Link></li>
                                <li><Link to="/terms-of-service">Terms of Service</Link></li>
                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                            </ul>
                        </div>
                        {/* Resources */}
                        <div className="col-lg-2 col-sm-6 single-footer-widget">
                            <h4>Resources</h4>
                            <ul>
                                <li><Link to="/dental-tips">Dental Tips</Link></li>
                                <li><Link to="/oral-health">Oral Health</Link></li>
                                <li><Link to="/insurance-info">Insurance Info</Link></li>
                                <li><Link to="/careers">Careers</Link></li>
                                <li><Link to="/contact-support">Contact Support</Link></li>
                            </ul>
                        </div>
                        {/* About Us */}
                        <div className="col-lg-2 col-sm-6 single-footer-widget">
                            <h4>About Us</h4>
                            <ul>
                                <li><Link to="/our-team">Our Team</Link></li>
                                <li><Link to="/our-clinic">Our Clinic</Link></li>
                                <li><Link to="/mission-vision">Mission & Vision</Link></li>
                                <li><Link to="/patient-stories">Patient Stories</Link></li>
                                <li><Link to="/contact-us">Contact Us</Link></li>
                            </ul>
                        </div>
                        {/* Newsletter */}
                        <div className="col-lg-4 col-md-6 single-footer-widget">
                            <h4>Newsletter</h4>
                            <p>Stay updated with our latest news and offers. Subscribe to our newsletter!</p>
                            <div className="form-wrap" id="mc_embed_signup">
                                <form
                                    target="_blank"
                                    action="https://spondonit.us12.list-manage.com/subscribe/post?u=YOUR_UNIQUE_ID&amp;id=YOUR_LIST_ID"
                                    method="get"
                                    className="form-inline"
                                >
                                    <input
                                        className="form-control"
                                        name="EMAIL"
                                        placeholder="Your Email Address"
                                        onFocus={(e) => e.target.placeholder = ''}
                                        onBlur={(e) => e.target.placeholder = 'Your Email Address'}
                                        required
                                        type="email"
                                    />
                                    <button className="click-btn btn btn-default" type="submit">
                                        <i className="ti-arrow-right"></i>
                                    </button>
                                    {/* Hidden field for bot protection */}
                                    <div style={{ position: "absolute", left: "-5000px" }}>
                                        <input
                                            name="b_YOUR_UNIQUE_ID_YOUR_LIST_ID"
                                            tabIndex="-1"
                                            value=""
                                            type="text"
                                        />
                                    </div>
                                    <div className="info"></div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* Footer Bottom */}
                    <div className="row footer-bottom d-flex justify-content-between align-items-center">
                        <p className="col-lg-6 col-sm-12 footer-text text-center text-lg-left">
                            &copy; {new Date().getFullYear()} Dr. Shukla Dental Clinic and implant Center. All Rights Reserved.
                        </p>
                        <div className="col-lg-6 col-sm-12 footer-social text-center text-lg-right">
                            <a href="https://facebook.com/gondiadentalclinic" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                            <a href="https://twitter.com/gondiadental" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                            <a href="https://instagram.com/gondiadentalclinic" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                            <a href="https://linkedin.com/company/gondiadentalclinic" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
                            <a href="https://youtube.com/gondiadentalclinic" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
                        </div>
                    </div>
                </div>
            </footer>
            {/* <!-- End Footer Area --> */}
        </>
    );
}

export default Footer;
