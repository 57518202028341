// PrivacyPolicy.js
import React from 'react';
import { Link } from 'react-router-dom';
// import './PrivacyPolicy.css'; // Import custom CSS

const PrivacyPolicy = () => {
  return (
    <>
      {/* Banner Section */}
      <section className="banner_area">
        <div className="banner_inner d-flex align-items-center">
          <div className="container">
            <div className="banner_content d-md-flex justify-content-between align-items-center">
              <div className="mb-3 mb-md-0">
                <h2>Privacy Policy</h2>
                <p>
                  Your privacy is important to us. Please read our privacy policy carefully to understand how we collect, use, and protect your personal information.
                </p>
              </div>
              {/* <div className="page_link">
                <Link to="/">Home</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* End Banner Section */}

      {/* Privacy Policy Content */}
      <section className="privacy-policy-area area-padding-top">
        <div className="container">
          <div className="privacy-policy-content">
            <h3>Introduction</h3>
            <p>
              At Gondia Dental Clinic, we are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website.
            </p>

            <h3>Information We Collect</h3>
            <p>
              We may collect personal identification information such as your name, email address, phone number, and other details you provide when you fill out forms on our website.
            </p>

            <h3>How We Use Your Information</h3>
            <p>
              We use the information we collect to respond to your inquiries, schedule appointments, and provide services you request. We may also use your information to send promotional communications and updates, with your consent.
            </p>

            <h3>Cookies and Tracking Technologies</h3>
            <p>
              Our website may use cookies and similar tracking technologies to enhance your experience. Cookies are small data files stored on your device that help us understand your preferences based on previous or current site activity.
            </p>

            <h3>Third-Party Disclosure</h3>
            <p>
              We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except as required by law or to trusted third parties who assist us in operating our website or conducting our business.
            </p>

            <h3>Data Security</h3>
            <p>
              We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.
            </p>

            <h3>Your Rights</h3>
            <p>
              You have the right to access, correct, or delete your personal information that we hold. You may also opt out of receiving promotional communications from us at any time.
            </p>

            <h3>Changes to This Policy</h3>
            <p>
              We may update our Privacy Policy from time to time. We encourage you to review this page periodically for any changes.
            </p>

            <h3>Contact Us</h3>
            <p>
              If you have any questions or concerns about our Privacy Policy, please contact us.
            </p>
            <Link to="/contact" className="btn btn-primary mt-3">
              Contact Us
            </Link>
          </div>
        </div>
      </section>
      {/* End Privacy Policy Content */}
    </>
  );
};

export default PrivacyPolicy;
