// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Import your page components
import Home from './page/Home';
import About from './page/About';
import Header from './components/Header';
import Contact from './page/Contact';
import Footer from './components/Footer';
import Services from './page/Services';
import Testimonials from './page/Testimonials';
import PreventiveCare from './page/PreventiveCare';
import RestorativeDentistry from './page/RestorativeDentistry';
import CosmeticDentistry from './page/CosmeticDentistry';
import Orthodontics from './page/Orthodontics';
import EmergencyCare from './page/EmergencyCare';
import PediatricDentistry from './page/PediatricDentistry';
import FAQ from './page/FAQ';
import ScrollToTop from './components/ScrollToTop';
import TermsAndConditions from './page/TermsAndConditions';
import PrivacyPolicy from './page/PrivacyPolicy';
import AlbumPage from './components/Album';
// import Department from './pages/Department';
// import Doctors from './pages/Doctors';
// import Blog from './pages/Blog';
// import BlogDetails from './pages/BlogDetails';
// import Element from './pages/Element';
// import Contact from './pages/Contact';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path='/album' element={<AlbumPage />}></Route>
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path='/album' element={<AlbumPage />}></Route>
        <Route path="/preventive-care" element={<PreventiveCare />} />
        <Route path="/restorative-dentistry" element={<RestorativeDentistry />} />
        <Route path="/cosmetic-dentistry" element={<CosmeticDentistry />} />
        <Route path="/orthodontics" element={<Orthodontics />} />
        <Route path="/emergency-care" element={<EmergencyCare />} />
        <Route path="/pediatric-dentistry" element={<PediatricDentistry />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/terms-of-service" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        

        {/* <Route path="/department" element={<Department />} />
        <Route path="/doctors" element={<Doctors />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-details" element={<BlogDetails />} />
        <Route path="/element" element={<Element />} />
        {/* Add more routes as needed */}
        {/* Optional 404 Not Found route */}
        {/* <Route path="*" element={<NotFound />} />  */}
      </Routes>
      
      <Footer/>
    </Router>
  );
}

export default App;


