// Testimonials.js

import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './Testimonials.css'; // Import custom CSS
import AlbumPage from '../components/Album';
import imageOne from '../assets/imageOne.jpeg'
import imageTwo from '../assets/imageTwo.jpeg'
import imageThree from '../assets/imageThree.jpeg'
import imageFour from '../assets/imageFour.jpeg'
import imageFive from '../assets/imageFive.jpeg'
import imageSix from '../assets/imageSix.jpeg'
import { Link } from 'react-router-dom';
import ImageCarousel from '../components/ImageCarousel';


const Testimonials = () => {
  const testimonials = [
    {
      name: 'Palee Ganweer',
      photo: imageOne, // Ensure this image exists in your public folder
      feedback:
        'I had persistent molar pain and needed extraction. Dr. Shukla performed a painless minor surgery efficiently.',
      stars: 5,
    },
    {
      name: 'Kunjal Katre',
      photo: imageTwo,
      feedback:
        'Fantastic experience at the clinic for root canal and dental implants.',
      stars: 5,
    },
    {
      name: 'Ayush Jharariya',
      photo: imageThree,
      feedback:
        'As an assistant manager, my RCT experience was excellent. Dr. Shukla and his team are highly recommended.',
      stars: 5,
    },
    {
      name: 'Sunita Vaidya',
      photo: imageFour,
      feedback:
        'Excellent braces treatment at Gondia Dental Clinic. Exceptional service and very cooperative. Highly recommended!',
      stars: 5,
    },
    {
      name: 'Krishna Patel',
      photo: imageFive,
      feedback:
        'Dr. Shukla performed a painless minor surgery to remove my problematic tooth. I\'m now pain-free. Excellent staff and advice.',
      stars: 5,
    },
    {
      name: 'Chetna Masani',
      photo: imageSix,
      feedback:
        'Best dental clinic in Gondia. Dr. Shukla removed my tooth painlessly, allowing my braces treatment to proceed smoothly. Very happy with the results.',
      stars: 5,
    },
    // {
    //   name: 'Anita Das',
    //   photo: '/images/testimonials/anita-das.jpg',
    //   feedback:
    //     'Excellent knowledge and perfect work. Best doctor for painless root canal treatment. Highly recommend.',
    //   stars: 5,
    // },
    // {
    //   name: 'Vinay Meshram',
    //   photo: '/images/testimonials/vinay-meshram.jpg',
    //   feedback:
    //     'Dr. Shukla fixed my teeth urgently and efficiently within 24 hours. Best dental clinic for fixed teeth.',
    //   stars: 5,
    // },
    // {
    //   name: 'Rupesh Tidke',
    //   photo: '/images/testimonials/roopesh-tidke.jpg',
    //   feedback:
    //     'Dr. Shukla removed my mother\'s wisdom tooth painlessly. Compared to Nagpur clinics, Shukla Dental Clinic is the best in Gondia.',
    //   stars: 5,
    // },
    // {
    //   name: 'Pranay Dhuware',
    //   photo: '/images/testimonials/pranay-dhuware.jpg',
    //   feedback:
    //     'As a police officer, I was unsatisfied with previous dental treatments. Dr. Shukla performed a great root canal and placed implants efficiently.',
    //   stars: 5,
    // },
  ];

  // Function to render star ratings
  const renderStars = (count) => {
    const stars = [];
    for (let i = 0; i < count; i++) {
      stars.push(<i key={i} className="ti-star star-icon"></i>);
    }
    return stars;
  };

  return (
    <>
      {/* <!--================Home Banner Area =================--> */}
      <section className="banner_area">
        <div className="banner_inner d-flex align-items-center">
          <div className="container">
            <div
              className="banner_content d-md-flex justify-content-between align-items-center"
            >
              <div className="mb-3 mb-md-0">
                <h2>What Our Patients Say</h2>
                <p>
                  Hear from our satisfied patients who have experienced top-notch dental care at Gondia Dental Clinic.
                </p>
              </div> 
              <div className="page_link">
                <Link to="/">Home</Link>
                <Link to="/preventive-care">Preventive Dental Care</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--================End Home Banner Area =================--> */}

      <section className=" background_one">
                <div className="container">
                    <div className="row justify-content-center">
                    <ImageCarousel/>
                    </div>
                </div>
            </section>

      <section className="testimonial-area area-padding-top">
        {/* <div>
        <AlbumPage />

        </div> */}
        <Container>
          <Row className="mt-5">
            {testimonials.map((testimonial, index) => (
              <Col key={index} md={6} lg={4} className="mb-4">
                <Card className="testimonial-card h-100 text-center p-3">
                  <Card.Body>
                    {/* <img
                      // src={testimonial.photo}
                      alt={testimonial.name}
                      className="testimonial-photo rounded-circle mb-3"
                    /> */}
                    <Card.Text className="testimonial-feedback">
                      "{testimonial.feedback}"
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer className="bg-transparent border-top-0">
                    <div className="testimonial-stars mb-2">
                      {renderStars(testimonial.stars)}
                    </div>
                    <Card.Title className="testimonial-name mb-0">- {testimonial.name}</Card.Title>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>


      
    </>
  );
};

export default Testimonials;














// import React from 'react';
// import { Container, Row, Col, Card } from 'react-bootstrap';
// import './Testimonials.css';
// import AlbumPage from './Album';
// import imageOne from '../assets/imageOne.jpeg'; // Example of importing image
// import imageTwo from '../assets/imageTwo.jpeg'; // Example of importing image
// import imageThree from '../assets/imageThree.jpeg'; // Example of importing image
// import imageFour from '../assets/imageFour.jpeg';
// import imageFive from '../assets/imageFive.jpeg';
// import imageSix from '../assets/imageSix.jpeg';

// const testimonials = [
//   {
//     name: 'Palee Ganweer',
//     photo: imageOne, // Use the imported image
//     feedback:
//       'I had persistent molar pain and needed extraction. Dr. Shukla performed a painless minor surgery efficiently.',
//     stars: 5,
//   },
//   {
//     name: 'Kunjal Katre',
//     photo: imageTwo, // Use the imported image
//     feedback: 'Fantastic experience at the clinic for root canal and dental implants.',
//     stars: 5,
//   },
//     {name: 'Ayush Jharariya',
//       photo: imageThree,
//       feedback:
//         'As an assistant manager, my RCT experience was excellent. Dr. Shukla and his team are highly recommended.',
//       stars: 5,
//     },
//     {
//       name: 'Sunita Vaidya',
//       photo: imageFour,
//       feedback:
//         'Excellent braces treatment at Gondia Dental Clinic. Exceptional service and very cooperative. Highly recommended!',
//       stars: 5,
//     },
//     {
//       name: 'Krishna Patel',
//       photo: imageFive,
//       feedback:
//         'Dr. Shukla performed a painless minor surgery to remove my problematic tooth. I\'m now pain-free. Excellent staff and advice.',
//       stars: 5,
//     },
//     {
//       name: 'Chetna Masani',
//       photo: imageSix,
//       feedback:
//           'Best dental clinic in Gondia. Dr. Shukla removed my tooth painlessly, allowing my braces treatment to proceed smoothly. Very happy with the results.',
//       stars: 5,
//       },
//   // Add the rest of the testimonials with imported images similarly
// ];

// const Testimonials = () => {
//   const renderStars = (count) => {
//     const stars = [];
//     for (let i = 0; i < count; i++) {
//       stars.push(<i key={i} className="ti-star star-icon"></i>);
//     }
//     return stars;
//   };

//   return (
//     <>
//       {/* <section className="banner_area">
//         <div className="banner_inner d-flex align-items-center">
//           <div className="container">
//             <div className="banner_content d-md-flex justify-content-between align-items-center"> 
//               <AlbumPage />
//             </div>
//           </div>
//         </div>
//       </section> */}

      

//       <section className="testimonial-area area-padding-top">
//         <Container>
//           <Row className="mt-5">
//             {testimonials.map((testimonial, index) => (
//               <Col key={index} md={6} lg={4} className="mb-4">
//                 <Card className="testimonial-card h-100 text-center p-3">
//                   <Card.Body>
//                     <img
//                       src={testimonial.photo} // Now it uses the imported photo
//                       alt={testimonial.name}
//                       className="testimonial-photo rounded-circle mb-3"
//                     />
//                     <Card.Text className="testimonial-feedback">
//                       "{testimonial.feedback}"
//                     </Card.Text>
//                   </Card.Body>
//                   <Card.Footer className="bg-transparent border-top-0">
//                     <div className="testimonial-stars mb-2">
//                       {renderStars(testimonial.stars)}
//                     </div>
//                     <Card.Title className="testimonial-name mb-0">- {testimonial.name}</Card.Title>
//                   </Card.Footer>
//                 </Card>
//               </Col>
//             ))}
//           </Row>
//         </Container>
//       </section>
//     </>
//   );
// };

// export default Testimonials;
