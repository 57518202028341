// FAQ.js 
import React, { useState } from 'react'; 
import { Link } from 'react-router-dom'; 
// import './FAQ.css'; // Import custom CSS

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? -1 : index);
    };

    const faqs = [
        {
            question: 'How often should I visit the dentist for a checkup?',
            answer:
                'It is recommended to visit the dentist every six months for a routine checkup and cleaning. Regular visits help maintain oral health and catch any issues early.',
        },
        {
            question: 'What should I do in a dental emergency?',
            answer:
                'If you have a dental emergency, such as a toothache, broken tooth, or lost filling, please contact our clinic immediately. We offer same-day appointments for emergencies.',
        },
        {
            question: 'Do you accept insurance?',
            answer:
                'Yes, we accept a variety of dental insurance plans. Please contact our office for more information about your specific plan.',
        },
        {
            question: 'What payment options are available?',
            answer:
                'We offer several payment options, including cash and UPI. Our team can help you find a payment solution that fits your budget.',
        },
        {
            question: 'How can I improve my oral hygiene at home?',
            answer:
                'Maintaining good oral hygiene includes brushing twice a day, flossing daily, and using mouthwash. Our team can provide personalized tips during your visit.',
        },
        // Add more FAQs as needed
    ];

    return (<>
       <section className="banner_area">
        <div className="banner_inner d-flex align-items-center">
          <div className="container">
            <div
              className="banner_content d-md-flex justify-content-between align-items-center"
            >
              <div className="mb-3 mb-md-0">
                <h2>Frequently Asked Questions</h2>
                                                <p>
                                                    Find answers to some of the most common questions our patients ask. If you need further assistance, please don't hesitate to contact us.
                                                </p>
              </div>
              {/* <div className="page_link">
                <Link to="/">Home</Link>
                <Link to="/preventive-care">Preventive Dental Care</Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!--================End Home Banner Area =================--> */}
    
    
        <section className="appointment-area">
            <div className="container">
                <div className="appointment-inner">
                    <div className="row">
                        <div className="col-sm-12 col-lg-12 offset-lg-0">
                            <section className="faq-area area-padding-top">
                                <div className="container">
                                

                                    {/* FAQ Section */}
                                    <div className="row mt-5">
                                        <div className="col-sm-12 col-lg-12">
                                            <h3>Have Some Questions?</h3>
                                            <div className="accordion" id="accordionExample">
                                                {faqs.map((faq, index) => (
                                                    <div className="card" key={index}>
                                                        <div className="card-header" id={`heading${index}`}>
                                                            <h5 className="mb-0">
                                                                <button
                                                                    className={`btn btn-link ${activeIndex === index ? '' : 'collapsed'}`}
                                                                    type="button"
                                                                    onClick={() => toggleAccordion(index)}
                                                                    aria-expanded={activeIndex === index}
                                                                    aria-controls={`collapse${index}`}
                                                                >
                                                                    {faq.question}
                                                                </button>
                                                            </h5>
                                                        </div>

                                                        <div
                                                            id={`collapse${index}`}
                                                            className={`collapse ${activeIndex === index ? 'show' : ''}`}
                                                            aria-labelledby={`heading${index}`}
                                                            data-parent="#accordionExample"
                                                        >
                                                            <div className="card-body">
                                                                {faq.answer}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Contact Section */}
                                    <div className="row mt-5">
                                        <div className="col-lg-12 text-center">
                                            <h3>Have More Questions?</h3>
                                            <p>
                                                If you have any other questions or concerns, feel free to reach out to us. Our friendly staff is here to help!
                                            </p>
                                            <Link to="/contact" className="btn btn-primary mt-3">
                                                Contact Us
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
};

export default FAQ;
