import React, { useEffect } from 'react';

const Contact = () => {
  useEffect(() => {
    // Initialize Google Map after component mounts
    const loadMap = () => {
      if (window.google) {
        const clinicLocation = { lat: 21.4710, lng: 80.4487 }; // Coordinates for Gondia Dental Clinic
        const mapStyles = [
          {
            featureType: "all",
            stylers: [
              { saturation: -50 },
              { lightness: 40 }
            ]
          },
          { elementType: 'labels.text.fill', stylers: [{ color: '#ffffff' }] }
        ];
        const map = new window.google.maps.Map(document.getElementById('map'), {
          center: clinicLocation,
          zoom: 15,
          styles: mapStyles,
          scrollwheel: false
        });
        new window.google.maps.Marker({
          position: clinicLocation,
          map: map,
          title: 'Gondia Dental Clinic'
        });
      }
    };

    // Load the Google Maps script dynamically
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_GOOGLE_MAPS_API_KEY&callback=initMap`;
    script.async = true;
    window.initMap = loadMap;
    document.body.appendChild(script);

    // Cleanup function to remove the script when component unmounts
    return () => {
      document.body.removeChild(script);
      delete window.initMap;
    };
  }, []);

  return (
    <>
      {/* <!--================Home Banner Area =================--> */}
      <section className="banner_area">
        <div className="banner_inner d-flex align-items-center">
          <div className="container">
            <div
              className="banner_content d-md-flex justify-content-between align-items-center"
            >
              <div className="mb-3 mb-md-0">
                <h2>Contact Us</h2>
                <p>Your Smile, Our Priority in Gondia</p>
              </div>
              <div className="page_link">
                <a href="index.html">Home</a>
                <a href="contact.html">Contact</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--================End Home Banner Area =================--> */}


      {/* <!-- ================ contact section start ================= --> */}
      <section className="contact-section area-padding">
        <div className="container">
          <div className=" mb-5 pb-4">
            {/* <div id="map" style={{ height: "480px" }}></div> */}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3713.2083414461854!2d80.19360557600636!3d21.460340372814056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a2baea9d50b20c1%3A0x2b873dfb3d0d9721!2sShukla%20mulitspeciality%20dental%20clinic%20and%20implant%20centre(BEST%20DENTAL%20CLINIC%20IN%20GONDIA)!5e0!3m2!1sen!2sin!4v1731059316451!5m2!1sen!2sin"
              width="100%"
              height="480"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Maps Embed"
            ></iframe>
          </div>


          <div className="row">
            <div className="col-12">
              <h2 className="contact-title">Get in Touch</h2>
            </div>
            <div className="col-lg-8">
              <form className="form-contact contact_form" action="contact_process.php" method="post" id="contactForm" noValidate="novalidate">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea className="form-control w-100" name="message" id="message" cols="30" rows="9" placeholder="Enter your message"></textarea>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input className="form-control" name="name" id="name" type="text" placeholder="Your Name" required />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input className="form-control" name="email" id="email" type="email" placeholder="Your Email Address" required />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <input className="form-control" name="subject" id="subject" type="text" placeholder="Subject" required />
                    </div>
                  </div>
                </div>
                <div className="form-group mt-3">
                  <button type="submit" className="button button-contactForm">Send Message</button>
                </div>
              </form>


            </div>

            <div className="col-lg-4">
              <div className="media contact-info">
                <span className="contact-info__icon"><i className="ti-home"></i></span>
                <div className="media-body">
                  <h3>New shree jee complex, beside manohar muncipal school, Gonpur, </h3>
                  <p>Rail Toly, Gondia, Maharashtra 441601</p>
                </div>
              </div>
              <div className="media contact-info">
                <span className="contact-info__icon"><i className="ti-tablet"></i></span>
                <div className="media-body">
                  <h3><a href="tel:+919096569324">+91 90965 69324</a></h3>
                  <p>Mon to Sat 9am to 7pm</p>
                </div>
              </div>
              <div className="media contact-info">
                <span className="contact-info__icon"><i className="ti-email"></i></span>
                <div className="media-body">
                  <h3><a href="mailto:contact@drshukladentalclinic.com">contact@drshukladentalclinic.com</a></h3>
                  <p>Send us your queries anytime!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ================ contact section end ================= --> */}


      {/* <!--================ Start Brands Area =================--> */}
      <section className="brands-area background_one">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="owl-carousel brand-carousel">
                {/* <!-- single-brand --> */}
                <div className="single-brand-item d-table">
                  <div className="d-table-cell">
                    <img src="img/brand/1.png" alt="Partner 1" />
                  </div>
                </div>
                {/* <!-- single-brand --> */}
                <div className="single-brand-item d-table">
                  <div className="d-table-cell">
                    <img src="img/brand/2.png" alt="Partner 2" />
                  </div>
                </div>
                {/* <!-- single-brand --> */}
                <div className="single-brand-item d-table">
                  <div className="d-table-cell">
                    <img src="img/brand/3.png" alt="Partner 3" />
                  </div>
                </div>
                {/* <!-- single-brand --> */}
                <div className="single-brand-item d-table">
                  <div className="d-table-cell">
                    <img src="img/brand/4.png" alt="Partner 4" />
                  </div>
                </div>
                {/* <!-- single-brand --> */}
                <div className="single-brand-item d-table">
                  <div className="d-table-cell">
                    <img src="img/brand/5.png" alt="Partner 5" />
                  </div>
                </div>
                {/* <!-- single-brand --> */}
                <div className="single-brand-item d-table">
                  <div className="d-table-cell">
                    <img src="img/brand/6.png" alt="Partner 6" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--================ End Brands Area =================--> */}
    </>
  )
}

export default Contact;
