import React from 'react';
import doctor from '../assets/doctor.png'


const About = () => {
    return (
        <>
            {/* <!--================Home Banner Area =================--> */}
            <section className="banner_area">
                <div className="banner_inner d-flex align-items-center">
                    <div className="container">
                        <div
                            className="banner_content d-md-flex justify-content-between align-items-center"
                        >
                            <div className="mb-3 mb-md-0">
                                <h2>About Us</h2>
                                <p>Your Trusted Dental Care in Gondia</p>
                            </div>
                            <div className="page_link">
                                <a href="index.html">Home</a>
                                <a href="about.html"> About Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--================End Home Banner Area =================--> */}


            {/* <!--================About Area =================--> */}
            <section className="about-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div class="mobile-only">
                            <img src={doctor} alt="Doctor" class="responsive-img" />
                        </div>
                        <div className="col-md-10 offset-md-1 col-lg-6 offset-lg-6 offset-xl-7 col-xl-5">


                            <div className="doctor-bio">
                                <h3 >Dr. Rahul R. Shukla, B.D.S.</h3>
                                <p>
                                    Serving you since 2010 at Shukla Dental Clinic Implant Centre, Aligner & Braces Clinic. Get your desired smile in just 24 hours.
                                </p>
                                <h4>Educational Qualifications and Certifications:</h4>
                                <ul className="doctor-qualifications">
                                    <li>B.D.S. (Government Dental College, Nagpur)</li>
                                    <li>Certification in Basic & Advanced Implantology, Mumbai</li>
                                    <li>Certification in basic and advanced implant prosthdontics, Mumbai</li>
                                    <li>Certification in painless dentistry </li>
                                    <li>Certification in Endodontics, Nagpur</li>
                                    <li>Certification in Orthodontics, Nagpur</li>
                                </ul>
                                {/* Contact Info */}
                                <div className="doctor-contact mt-4">
                                    <h5>Contact Information:</h5>
                                    <p>
                                        <strong>Mobile Number:</strong>{' '}
                                        <a href="tel:+919096569324">+91 90965 69324</a>
                                    </p>

                                    {/* Social Media Links */}
                                    <div className="doctor-social-links">
                                        <a href="https://www.facebook.com/shukladentalclinic" target="_blank" rel="noopener noreferrer" style={{ marginRight: "5px" }}><i className="ti-facebook"></i></a>
                                        <a href="https://www.instagram.com/shukladrrahul" target="_blank" rel="noopener noreferrer"><i className="ti-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--================About Area End =================--> */}



            {/* <!--================ Appointment Area Starts =================--> */}
            <section className="appointment-area area-padding-top">
                <div className="container">

                    <div className="appointment-inner">
                        <div className="row">
                            <div className="col-sm-12 col-lg-5 offset-lg-1">
                                <h3>Have Some Questions?</h3>
                                <div className="accordion" id="accordionExample">

                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    What services do you offer?
                                                </button>
                                            </h5>
                                        </div>

                                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                            <div className="card-body">
                                                We offer a wide range of dental services including preventive care, restorative treatments, cosmetic dentistry, orthodontics, and emergency dental care to ensure your oral health is maintained at its best.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    How can I schedule an appointment?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                            <div className="card-body">
                                                You can easily schedule an appointment by clicking the "Book an Appointment" button on our website, calling our office directly, or visiting us in person during our working hours.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    Do you accept insurance?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                            <div className="card-body">
                                                Yes, we accept a variety of dental insurance plans. Please contact our office to verify if your insurance is accepted and to understand your coverage details.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="headingFour">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    What are your office hours?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                            <div className="card-body">
                                                Our office is open Monday through Friday from 9:00 AM to 6:00 PM, and Saturdays from 9:00 AM to 1:00 PM. We are closed on Sundays.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="headingFive">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                    How do I prepare for my dental appointment?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                            <div className="card-body">
                                                Please arrive a few minutes early for your appointment, bring any necessary dental records or insurance information, and inform us of any medications you are currently taking or any dental concerns you may have.
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="appointment-form">
                                    <h3>Make an Appointment</h3>
                                    <form action="#">
                                        <div className="form-group">
                                            <label>Full Name</label>
                                            <input
                                                type="text"
                                                placeholder="Your Name"
                                                onFocus={(e) => e.target.placeholder = ''}
                                                onBlur={(e) => e.target.placeholder = 'Your Name'}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input
                                                type="email"
                                                placeholder="Your Email"
                                                onFocus={(e) => e.target.placeholder = ''}
                                                onBlur={(e) => e.target.placeholder = 'Your Email'}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Message</label>
                                            <textarea
                                                name="message"
                                                cols="20"
                                                rows="7"
                                                placeholder="Message"
                                                onFocus={(e) => e.target.placeholder = ''}
                                                onBlur={(e) => e.target.placeholder = 'Message'}
                                                required
                                            ></textarea>
                                        </div>
                                        <a href="#" className="main_btn">Make an Appointment</a>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </section>
            {/* <!--================ Appointment Area End =================--> */}


            {/* <!-- ================ Testimonial Section Start ================= --> */}
            <section className="testimonial">
                <div className="container">
                    <div className="testi_slider owl-carousel owl-theme">
                        <div className="item">
                            <div className="testi_item">
                                <div className="testimonial_image">
                                    <img src="img/elements/tes1.jpg" alt="Patient" />
                                </div>
                                <div className="testi_item_content">
                                    <p>
                                        “The team at Gondia Dental Clinic made my visit comfortable and stress-free. Their professionalism and care are unmatched.”
                                    </p>
                                    <h4>- Meera Kulkarni -</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="testi_item">
                                <div className="testimonial_image">
                                    <img src="img/elements/tes2.jpg" alt="Patient" />
                                </div>
                                <div className="testi_item_content">
                                    <p>
                                        “Excellent service and friendly staff. Dr. Anjali Sharma provided top-notch care and explained everything clearly.”
                                    </p>
                                    <h4>- Rajesh Verma -</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="testi_item">
                                <div className="testimonial_image">
                                    <img src="img/elements/tes3.jpg" alt="Patient" />
                                </div>
                                <div className="testi_item_content">
                                    <p>
                                        “I highly recommend Gondia Dental Clinic for their comprehensive dental services and compassionate approach to patient care.”
                                    </p>
                                    <h4>- Anita Desai -</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- ================ Testimonial Section End ================= --> */}



            {/* <!--================ Start Brands Area =================--> */}
            <section className="brands-area border-bottom">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="owl-carousel brand-carousel">
                                {/* <!-- single-brand --> */}
                                <div className="single-brand-item d-table">
                                    <div className="d-table-cell">
                                        <img src="img/brand/1.png" alt="Partner 1" />
                                    </div>
                                </div>
                                {/* <!-- single-brand --> */}
                                <div className="single-brand-item d-table">
                                    <div className="d-table-cell">
                                        <img src="img/brand/2.png" alt="Partner 2" />
                                    </div>
                                </div>
                                {/* <!-- single-brand --> */}
                                <div className="single-brand-item d-table">
                                    <div className="d-table-cell">
                                        <img src="img/brand/3.png" alt="Partner 3" />
                                    </div>
                                </div>
                                {/* <!-- single-brand --> */}
                                <div className="single-brand-item d-table">
                                    <div className="d-table-cell">
                                        <img src="img/brand/4.png" alt="Partner 4" />
                                    </div>
                                </div>
                                {/* <!-- single-brand --> */}
                                <div className="single-brand-item d-table">
                                    <div className="d-table-cell">
                                        <img src="img/brand/5.png" alt="Partner 5" />
                                    </div>
                                </div>
                                {/* <!-- single-brand --> */}
                                <div className="single-brand-item d-table">
                                    <div className="d-table-cell">
                                        <img src="img/brand/6.png" alt="Partner 6" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--================ End Brands Area =================--> */}
        </>
    )
}

export default About;
