// EmergencyCare.js
import React from 'react'; 
import { Container, Row, Col, Card } from 'react-bootstrap'; 
import { Link } from 'react-router-dom';
import './PreventiveCare.css'; // Import custom CSS

const EmergencyCare = () => {
  const emergencyServices = [
    {
      title: 'Toothache Relief',
      description:
        'Immediate diagnosis and treatment to alleviate tooth pain and address the underlying cause.',
      icon: 'flaticon-toothache', // Replace with relevant icon class or image
    },
    {
      title: 'Knocked-Out Tooth',
      description:
        'Prompt care to increase the chances of saving and re-implanting a knocked-out tooth.',
      icon: 'flaticon-knocked-out', // Replace with relevant icon class or image
    },
    {
      title: 'Broken or Chipped Tooth Repair',
      description:
        'Restoration of damaged teeth to prevent further complications and restore appearance.',
      icon: 'flaticon-broken-tooth', // Replace with relevant icon class or image
    },
    {
      title: 'Dental Abscess Treatment',
      description:
        'Emergency treatment for infections to prevent the spread and alleviate pain.',
      icon: 'flaticon-abscess', // Replace with relevant icon class or image
    },
    {
      title: 'Lost Filling or Crown Repair',
      description:
        'Quick replacement or repair of lost fillings or crowns to protect your tooth.',
      icon: 'flaticon-crown', // Replace with relevant icon class or image
    },
    {
      title: 'Emergency Extractions',
      description:
        'Safe removal of teeth when necessary to relieve pain and prevent further issues.',
      icon: 'flaticon-extraction', // Replace with relevant icon class or image
    },
  ];

  return (<>
     <section className="banner_area">
        <div className="banner_inner d-flex align-items-center">
          <div className="container">
            <div
              className="banner_content d-md-flex justify-content-between align-items-center"
            >
              <div className="mb-3 mb-md-0">
                <h2>Emergency Dental Care</h2>
              <p>
                At Gondia Dental Clinic, we provide prompt and effective emergency dental services to address urgent oral health issues and alleviate discomfort.
              </p>
              </div>
              {/* <div className="page_link">
                <Link to="/">Home</Link>
                <Link to="/preventive-care">Preventive Dental Care</Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!--================End Home Banner Area =================--> */}
  
  
    <section className="emergency-care-area area-padding-top">
      <Container>
        

        {/* Services Section */}
        <Row className="mt-5">
          {emergencyServices.map((service, index) => (
            <Col key={index} md={6} lg={4} className="mb-4">
              <Card className="emergency-service-card h-100 text-center p-4">
                <div className="service-icon mb-3">
                  <i className={service.icon}></i>
                </div>
                <Card.Body>
                  <Card.Title>{service.title}</Card.Title>
                  <Card.Text>{service.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {/* What to Do in a Dental Emergency */}
        <Row className="mt-5">
          <Col lg={6}>
            <div className="emergency-image">
              <img
                src="/images/emergency-care.jpg" // Replace with your image path
                alt="Emergency Dental Care"
                className="img-fluid rounded"
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="emergency-content">
              <h3>What to Do in a Dental Emergency</h3>
              <ul className="emergency-tips-list">
                <li>Stay calm and assess the situation.</li>
                <li>Contact us immediately for guidance and to schedule an emergency appointment.</li>
                <li>Keep any knocked-out or broken pieces of teeth moist.</li>
                <li>Avoid applying aspirin directly to the affected area.</li>
                <li>Use a cold compress to reduce swelling.</li>
              </ul>
              <Link to="/contact" className="learn-more btn btn-primary mt-3">
                Call Us Now
              </Link>
            </div>
          </Col>
        </Row>

        {/* Optional: Testimonials Related to Emergency Care */}
        <Row className="mt-5">
          <Col lg={12}>
            <div className="related-testimonials">
              <h3 className="text-center mb-4">Patient Testimonials</h3>
              <Row>
                {/* Example Testimonial */}
                <Col md={6} lg={4} className="mb-4">
                  <Card className="testimonial-card h-100 text-center p-3">
                    <Card.Body>
                      <img
                        src="/images/testimonials/michael.jpg" // Replace with actual image path
                        alt="Michael Lee"
                        className="testimonial-photo rounded-circle mb-3"
                      />
                      <Card.Text className="testimonial-feedback">
                        "I had a terrible toothache over the weekend, and Gondia Dental Clinic took care of me promptly. Their emergency service was a lifesaver!"
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="bg-transparent border-top-0">
                      <Card.Title className="testimonial-name mb-0">- Michael Lee</Card.Title>
                    </Card.Footer>
                  </Card>
                </Col>
                {/* Add more testimonials as needed */}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
};

export default EmergencyCare;
