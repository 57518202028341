// PediatricDentistry.js
import React from 'react'; 
import { Container, Row, Col, Card } from 'react-bootstrap'; 
import { Link } from 'react-router-dom';
import './PreventiveCare.css'; // Import custom CSS

const PediatricDentistry = () => {
  const pediatricServices = [
    {
      title: 'Infant Oral Care',
      description:
        'Early dental checkups for infants to ensure proper oral development and hygiene education for parents.',
      icon: 'flaticon-baby', // Replace with relevant icon class or image
    },
    {
      title: 'Dental Sealants for Kids',
      description:
        'Protective coatings applied to children’s molars to prevent cavities.',
      icon: 'flaticon-sealant', // Replace with relevant icon class or image
    },
    {
      title: 'Fluoride Treatments',
      description:
        'Strengthen your child’s tooth enamel to resist decay.',
      icon: 'flaticon-fluoride', // Replace with relevant icon class or image
    },
    {
      title: 'Habit Counseling',
      description:
        'Guidance to help stop thumb sucking and pacifier use.',
      icon: 'flaticon-counseling', // Replace with relevant icon class or image
    },
    {
      title: 'Dental Fillings',
      description:
        'Tooth-colored fillings to repair cavities in primary and permanent teeth.',
      icon: 'flaticon-filling', // Replace with relevant icon class or image
    },
    {
      title: 'Emergency Dental Care for Children',
      description:
        'Prompt treatment for dental injuries like knocked-out or fractured teeth.',
      icon: 'flaticon-emergency', // Replace with relevant icon class or image
    },
  ];

  return (
    <section className="pediatric-dentistry-area area-padding-top">
      <Container>
        {/* Header Section */}
        <Row className="justify-content-center">
          <Col lg={8}>
            <div className="section-title text-center">
              <h2>Pediatric Dentistry</h2>
              <p>
                At Gondia Dental Clinic, we specialize in providing gentle and comprehensive dental care for children, ensuring a positive experience for our youngest patients.
              </p>
            </div>
          </Col>
        </Row>

        {/* Services Section */}
        <Row className="mt-5">
          {pediatricServices.map((service, index) => (
            <Col key={index} md={6} lg={4} className="mb-4">
              <Card className="pediatric-service-card h-100 text-center p-4">
                <div className="service-icon mb-3">
                  <i className={service.icon}></i>
                </div>
                <Card.Body>
                  <Card.Title>{service.title}</Card.Title>
                  <Card.Text>{service.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Benefits Section */}
        <Row className="mt-5">
          <Col lg={6}>
            <div className="benefits-image">
              <img
                src="/images/pediatric-dentistry.jpg" // Replace with your image path
                alt="Pediatric Dentistry"
                className="img-fluid rounded"
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="benefits-content">
              <h3>Why Choose Pediatric Dentistry</h3>
              <ul className="benefits-list">
                <li>Specialized care tailored to children's needs.</li>
                <li>Early detection of dental issues.</li>
                <li>Education on proper oral hygiene habits.</li>
                <li>Comfortable and friendly environment.</li>
                <li>Preventive measures to ensure long-term oral health.</li>
              </ul>
              <Link to="/contact" className="learn-more btn btn-primary mt-3">
                Schedule an Appointment
              </Link>
            </div>
          </Col>
        </Row>

        {/* Optional: Testimonials Related to Pediatric Dentistry */}
        <Row className="mt-5">
          <Col lg={12}>
            <div className="related-testimonials">
              <h3 className="text-center mb-4">Parent Testimonials</h3>
              <Row>
                {/* Example Testimonial */}
                <Col md={6} lg={4} className="mb-4">
                  <Card className="testimonial-card h-100 text-center p-3">
                    <Card.Body>
                      <img
                        src="/images/testimonials/linda-and-sophie.jpg" // Replace with actual image path
                        alt="Linda and Sophie"
                        className="testimonial-photo rounded-circle mb-3"
                      />
                      <Card.Text className="testimonial-feedback">
                        "The staff at Gondia Dental Clinic made my daughter's first dental visit enjoyable. She can't wait to go back!"
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="bg-transparent border-top-0">
                      <Card.Title className="testimonial-name mb-0">- Linda and Sophie</Card.Title>
                    </Card.Footer>
                  </Card>
                </Col>
                {/* Add more testimonials as needed */}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PediatricDentistry;
