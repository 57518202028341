// TermsAndConditions.js
import React from 'react';
import { Link } from 'react-router-dom';
// import './TermsAndConditions.css'; // Import custom CSS

const TermsAndConditions = () => {
  return (
    <>
      {/* Banner Section */}
      <section className="banner_area">
        <div className="banner_inner d-flex align-items-center">
          <div className="container">
            <div className="banner_content d-md-flex justify-content-between align-items-center">
              <div className="mb-3 mb-md-0">
                <h2>Terms and Conditions</h2>
                <p>
                  Please read these terms and conditions carefully before using our website.
                </p>
              </div>
              {/* <div className="page_link">
                <Link to="/">Home</Link>
                <Link to="/terms-and-conditions">Terms and Conditions</Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* End Banner Section */}

      {/* Terms and Conditions Content */}
      <section className="terms-area area-padding-top">
        <div className="container">
          <div className="terms-content">
            <h3>Introduction</h3>
            <p>
              Welcome to Gondia Dental Clinic. These terms and conditions outline the rules and regulations for the use of our website.
            </p>

            <h3>Acceptance of Terms</h3>
            <p>
              By accessing this website, we assume you accept these terms and conditions. Do not continue to use the website if you do not agree to all the terms and conditions stated on this page.
            </p>

            <h3>Cookies</h3>
            <p>
              We employ the use of cookies. By accessing our website, you agreed to use cookies in agreement with our Privacy Policy.
            </p>

            <h3>License</h3>
            <p>
              Unless otherwise stated, Gondia Dental Clinic owns the intellectual property rights for all material on this website. All intellectual property rights are reserved. You may access this from our website for your own personal use subjected to restrictions set in these terms and conditions.
            </p>

            <h3>User Comments</h3>
            <p>
              Certain parts of this website offer the opportunity for users to post comments. Gondia Dental Clinic does not filter, edit, or review comments prior to their presence on the website. Comments do not reflect the views and opinions of Gondia Dental Clinic, its agents, or affiliates.
            </p>

            <h3>Hyperlinking to Our Content</h3>
            <p>
              The following organizations may link to our website without prior written approval:
            </p>
            <ul>
              <li>Government agencies;</li>
              <li>Search engines;</li>
              <li>News organizations;</li>
              <li>Online directory distributors;</li>
              <li>System-wide Accredited Businesses.</li>
            </ul>

            <h3>iFrames</h3>
            <p>
              Without prior approval and written permission, you may not create frames around our webpages that alter in any way the visual presentation or appearance of our website.
            </p>

            <h3>Content Liability</h3>
            <p>
              We shall not be held responsible for any content that appears on your website. You agree to protect and defend us against all claims arising on your website.
            </p>

            <h3>Reservation of Rights</h3>
            <p>
              We reserve the right to request that you remove all links or any particular link to our website. You approve to immediately remove all links to our website upon request.
            </p>

            <h3>Removal of Links from Our Website</h3>
            <p>
              If you find any link on our website that is offensive for any reason, you are free to contact us at any time. We will consider requests to remove links but are not obligated to do so.
            </p>

            <h3>Disclaimer</h3>
            <p>
              To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website.
            </p>

            <h3>Contact Us</h3>
            <p>
              If you have any questions about these Terms and Conditions, please contact us.
            </p>
            <Link to="/contact" className="btn btn-primary mt-3">
              Contact Us
            </Link>
          </div>
        </div>
      </section>
      {/* End Terms and Conditions Content */}
    </>
  );
};

export default TermsAndConditions;
